
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "../styles/tailwind.scss"
import "../styles/fonts.scss"
import "../styles/globals.scss"
import { NavContextProvider } from "../store/nav-context"
import { LoaderContextProvider } from "../store/loader-context"

const App = ({ Component, pageProps }) => {
  return (
    <LoaderContextProvider>
      <NavContextProvider>
        <Component {...pageProps} />
      </NavContextProvider>
    </LoaderContextProvider>
  )
}

const __Page_Next_Translate__ = App

// hack to force a serverside reload and to prevent auto static optimization
/*export async function getInitialProps() {
  return { test: [] }
}*/


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  