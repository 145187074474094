import { createContext, useState } from "react"

const NavContext = createContext({
  isMenuOpen: false,
  toggleMenu: () => {},
})

export function NavContextProvider({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  function toggleMenu(state) {
    setIsMenuOpen((/*prevIsMenuOpen*/) => {
      return typeof state !== "undefined" ? state : !isMenuOpen
    })
  }

  return (
    <NavContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </NavContext.Provider>
  )
}

export default NavContext
