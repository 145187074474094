import { createContext, useEffect, useState } from "react"
import { useRouter } from "next/router"

const LoaderContext = createContext({
  isLoading: false,
})

export function LoaderContextProvider({ children }) {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    function start() {
      setIsLoading(true)
    }

    function stop() {
      setIsLoading(false)
    }

    router.events.on("routeChangeStart", start)
    router.events.on("routeChangeComplete", stop)
    router.events.on("routeChangeError", stop)

    return () => {
      router.events.off("routeChangeStart")
      router.events.off("routeChangeComplete")
      router.events.off("routeChangeError")
    }
  }, [])

  return (
    <LoaderContext.Provider value={{ isLoading }}>
      {children}
    </LoaderContext.Provider>
  )
}

export default LoaderContext
